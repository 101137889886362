export const REPORT_EMPLOYEES_TIMESHEET_REQUEST = 'REPORT_EMPLOYEES_TIMESHEET_REQUEST';
export const REPORT_EMPLOYEES_TIMESHEET_SUCCESS = 'REPORT_EMPLOYEES_TIMESHEET_SUCCESS';
export const REPORT_EMPLOYEES_TIMESHEET_FAIL = 'REPORT_EMPLOYEES_TIMESHEET_FAIL';

export const REPORT_EMPLOYEES_NOT_ENTERED_TIMESHEET_REQUEST = 'REPORT_EMPLOYEES_NOT_ENTERED_TIMESHEET_REQUEST';
export const REPORT_EMPLOYEES_NOT_ENTERED_TIMESHEET_SUCCESS = 'REPORT_EMPLOYEES_NOT_ENTERED_TIMESHEET_SUCCESS';
export const REPORT_EMPLOYEES_NOT_ENTERED_TIMESHEET_FAIL = 'REPORT_EMPLOYEES_NOT_ENTERED_TIMESHEET_FAIL';

export const REPORT_UPDATE_EMPLOYEE_TIMESHEET_REQUEST = 'REPORT_UPDATE_EMPLOYEE_TIMESHEET_REQUEST';
export const REPORT_UPDATE_EMPLOYEE_TIMESHEET_SUCCESS = 'REPORT_UPDATE_EMPLOYEE_TIMESHEET_SUCCESS';
export const REPORT_UPDATE_EMPLOYEE_TIMESHEET_FAIL = 'REPORT_UPDATE_EMPLOYEE_TIMESHEET_FAIL';

export const REPORT_DELETE_EMPLOYEE_TIMESHEET_REQUEST = 'REPORT_DELETE_EMPLOYEE_TIMESHEET_REQUEST';
export const REPORT_DELETE_EMPLOYEE_TIMESHEET_SUCCESS = 'REPORT_DELETE_EMPLOYEE_TIMESHEET_SUCCESS';
export const REPORT_DELETE_EMPLOYEE_TIMESHEET_FAIL = 'REPORT_DELETE_EMPLOYEE_TIMESHEET_FAIL';

export const REPORT_ENTRY_VALIDATION_FAIL = 'REPORT_ENTRY_VALIDATION_FAIL';

export const REPORT_RESET = 'REPORT_RESET';
