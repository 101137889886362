export const TIMESHEET_REQUEST = 'TIMESHEET_REQUEST';
export const TIMESHEET_SUCCESS = 'TIMESHEET_SUCCESS';
export const TIMESHEET_FAIL = 'TIMESHEET_FAIL';
export const TIMESHEET_POPULATE = 'TIMESHEET_POPULATE';

export const TIMESHEET_CREATE_ENTRY = 'TIMESHEET_CREATE_ENTRY';
export const TIMESHEET_DELETE_ENTRY = 'TIMESHEET_DELETE_ENTRY';
export const TIMESHEET_UPDATE_ENTRY = 'TIMESHEET_UPDATE_ENTRY';

export const TIMESHEET_SUBMIT_REQUEST = 'TIMESHEET_SUBMIT_REQUEST';
export const TIMESHEET_SUBMIT_VALIDATED = 'TIMESHEET_SUBMIT_VALIDATED';
export const TIMESHEET_VALIDATION_FAIL = 'TIMESHEET_VALIDATION_FAIL';
export const TIMESHEET_SUBMIT_SUCCESS = 'TIMESHEET_SUBMIT_SUCCESS';
export const TIMESHEET_SUBMIT_FAIL = 'TIMESHEET_SUBMIT_FAIL';

export const TIMESHEET_UPDATE_COMMENTS = 'TIMESHEET_UPDATE_COMMENTS';
