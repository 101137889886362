"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const hashRegEx = /#/;
const hexRegEx = /0x/i;
let isRGB;
let isArray;
let isHexString;
let isHexNumber;
function fontColorContrast(hexColorOrRedOrArray, greenOrThreshold, blue, threshold) {
    let red = 0;
    let green = 0;
    isRGB = !!(blue !== undefined);
    isArray = Array.isArray(hexColorOrRedOrArray);
    isHexString = typeof hexColorOrRedOrArray === 'string' && !isRGB;
    isHexNumber = typeof hexColorOrRedOrArray === 'number' && !isRGB;
    if (isHexString || isHexNumber) {
        [red, green, blue] = hexColorToRGB(hexColorOrRedOrArray);
        threshold = greenOrThreshold;
    }
    else if (isRGB || isArray) {
        [red, green, blue, threshold] = arrayOrRgbToRGB(hexColorOrRedOrArray, greenOrThreshold, blue, threshold);
    }
    else {
        // Not a color, respond with white color
        return '#ffffff';
    }
    return contrastFromHSP(red, green, blue, threshold);
}
exports.default = fontColorContrast;
/**
 * Converts a hexadecimal string to it's correspondent integer
 * @param hexString The hexadecimal string
 * @returns The integer value
 */
function hexToDec(hexString) {
    const decString = (hexString).replace(/[^a-f0-9]/gi, '');
    return parseInt(decString, 16);
}
/**
 * Converts a ColorIntensity string or number, with all possibilities (e.g. '#009', '009', '#000099', '000099', 153, 0x00099) to the respective RGB values
 * @param hexColor The color string or number
 * @returns The array with the RGB values
 * @example All these examples produces the same value
 * hexColorToRGB('#0C9')
 * hexColorToRGB('0C9')
 * hexColorToRGB('#00CC99')
 * hexColorToRGB('00cc99')
 * hexColorToRGB(52377)
 * hexColorToRGB(0x00Cc99)
 */
function hexColorToRGB(hexColor) {
    let red, green, blue;
    const hasHash = hashRegEx.test(hexColor);
    const hasHex = hexRegEx.test(hexColor);
    const color = isHexNumber
        ? hexColor.toString(16)
        : hasHash
            ? hexColor.replace(hashRegEx, '')
            : hasHex
                ? hexColor.replace(hexRegEx, '')
                : hexColor;
    // Color has only a single char in the last digit, so the last digit must be repeated, and red and green are 0
    if (color.length === 1) {
        red = 0;
        green = 0;
        blue = hexToDec(color.repeat(2));
        // Color has two chars in the last digit, so red and green are 0
    }
    else if (color.length === 2) {
        red = 0;
        green = 0;
        blue = hexToDec(color);
        // Color has one chars for each color, so they must be repeated
    }
    else if (color.length === 3) {
        red = hexToDec(color[0].repeat(2));
        green = hexToDec(color[1].repeat(2));
        blue = hexToDec(color[2].repeat(2));
        // Color has only for chars, so red is 0
    }
    else if (color.length === 4) {
        red = 0;
        green = hexToDec(color.substr(0, 2));
        blue = hexToDec(color.substr(2, 2));
        // All chars are filled, so no transformation is needed
    }
    else {
        red = hexToDec(color.substr(0, 2));
        green = hexToDec(color.substr(2, 2));
        blue = hexToDec(color.substr(4, 2));
    }
    return [red, green, blue];
}
/**
 * Converts a color array or separated in RGB to the respective RGB values
 * @param redOrArray The RGB array or the color red
 * @param greenOrThreshold The color green
 * @param blue The color blue
 * @returns The array with the RGB values
 * @example All these examples produces the same value
 * arrayOrRgbToRGB(0, 0xcc, 153)
 * arrayOrRgbToRGB(0x0, 0xcc, 153)
 * arrayOrRgbToRGB(0, 204, 0x99)
 * arrayOrRgbToRGB([0, 0xcc, 153])
 * arrayOrRgbToRGB([0x0, 0xcc, 153])
 * arrayOrRgbToRGB([0, 204, 0x99])
 */
function arrayOrRgbToRGB(redOrArray, greenOrThreshold, blue, threshold) {
    let r = 0;
    let g = 0;
    let b = 0;
    let t = 0;
    if (isArray) {
        r = redOrArray[0];
        g = redOrArray[1];
        b = redOrArray[2];
        t = greenOrThreshold;
    }
    else if (isRGB) {
        r = redOrArray;
        g = greenOrThreshold;
        b = blue;
        t = threshold;
    }
    return [r, g, b, t];
}
/**
 * Calculates the best color (black or white) to contrast with the passed RGB color using the algorithm from https://alienryderflex.com/hsp.html
 * @param red The color red value
 * @param green The color green value
 * @param blue The color blue value
 * @param threshold Contrast threshold to control the resulting font color, float values from 0 to 1. Default is 0.5.
 * @returns Black or White depending on the best possible contrast
 */
function contrastFromHSP(red, green, blue, threshold = 0.5) {
    const pRed = 0.299;
    const pGreen = 0.587;
    const pBlue = 0.114;
    const contrast = Math.sqrt(pRed * Math.pow((red / 255), 2) +
        pGreen * Math.pow((green / 255), 2) +
        pBlue * Math.pow((blue / 255), 2));
    return contrast > threshold
        ? '#000000'
        : '#ffffff';
}
