export const CLIENTLIST_FETCH_REQUEST = 'CLIENTLIST_FETCH_REQUEST';
export const CLIENTLIST_FETCH_SUCCESS = 'CLIENTLIST_FETCH_SUCCESS';
export const CLIENTLIST_FETCH_FAIL = 'CLIENTLIST_FETCH_FAIL';

export const CLIENT_FETCH_REQUEST = 'CLIENT_FETCH_REQUEST';
export const CLIENT_FETCH_SUCCESS = 'CLIENT_FETCH_SUCCESS';
export const CLIENT_FETCH_FAIL = 'CLIENT_FETCH_FAIL';

export const CLIENT_CREATE_REQUEST = 'CLIENT_CREATE_REQUEST';
export const CLIENT_CREATE_SUCCESS = 'CLIENT_CREATE_SUCCESS';
export const CLIENT_CREATE_FAIL = 'CLIENT_CREATE_FAIL';

export const CLIENT_UPDATE_REQUEST = 'CLIENT_UPDATE_REQUEST';
export const CLIENT_UPDATE_SUCCESS = 'CLIENT_UPDATE_SUCCESS';
export const CLIENT_UPDATE_FAIL = 'CLIENT_UPDATE_FAIL';

export const CLIENT_DELETE_REQUEST = 'CLIENT_DELETE_REQUEST';
export const CLIENT_DELETE_SUCCESS = 'CLIENT_DELETE_SUCCESS';
export const CLIENT_DELETE_FAIL = 'CLIENT_DELETE_FAIL';

export const RESET_REDIRECT = 'RESET_REDIRECT';
