export const JOB_CREATE_REQUEST = 'JOB_CREATE_REQUEST';
export const JOB_CREATE_SUCCESS = 'JOB_CREATE_SUCCESS';
export const JOB_CREATE_FAIL = 'JOB_CREATE_FAIL';

export const JOBLIST_FETCH_REQUEST = 'JOBLIST_FETCH_REQUEST';
export const JOBLIST_FETCH_SUCCESS = 'JOBLIST_FETCH_SUCCESS';
export const JOBLIST_FETCH_FAIL = 'JOBLIST_FETCH_FAIL';

export const LARGE_JOBLIST_FETCH_REQUEST = 'LARGE_JOBLIST_FETCH_REQUEST';
export const LARGE_JOBLIST_FETCH_SUCCESS = 'LARGE_JOBLIST_FETCH_SUCCESS';
export const LARGE_JOBLIST_FETCH_FAIL = 'LARGE_JOBLIST_FETCH_FAIL';

export const JOB_FETCH_REQUEST = 'JOB_FETCH_REQUEST';
export const JOB_FETCH_SUCCESS = 'JOB_FETCH_SUCCESS';
export const JOB_FETCH_FAIL = 'JOB_FETCH_FAIL';

export const JOB_UPDATE_REQUEST = 'JOB_UPDATE_REQUEST';
export const JOB_UPDATE_SUCCESS = 'JOB_UPDATE_SUCCESS';
export const JOB_UPDATE_FAIL = 'JOB_UPDATE_FAIL';

export const JOB_DELETE_REQUEST = 'JOB_DELETE_REQUEST';
export const JOB_DELETE_SUCCESS = 'JOB_DELETE_SUCCESS';
export const JOB_DELETE_FAIL = 'JOB_DELETE_FAIL';

export const JOB_RESET_REDIRECT = 'JOB_RESET_REDIRECT';

export const JOBPARTLIST_FETCH_REQUEST = 'JOBPARTLIST_FETCH_REQUEST';
export const JOBPARTLIST_FETCH_SUCCESS = 'JOBPARTLIST_FETCH_SUCCESS';
export const JOBPARTLIST_FETCH_FAIL = 'JOBPARTLIST_FETCH_FAIL';

export const JOBPARTLIST_UPDATE_REQUEST = 'JOBPARTLIST_UPDATE_REQUEST';
export const JOBPARTLIST_UPDATE_SUCCESS = 'JOBPARTLIST_UPDATE_SUCCESS';
export const JOBPARTLIST_UPDATE_FAIL = 'JOBPARTLIST_UPDATE_FAIL';

export const JOBPART_CREATE_REQUEST = 'JOBPART_CREATE_REQUEST';
export const JOBPART_CREATE_SUCCESS = 'JOBPART_CREATE_SUCCESS';
export const JOBPART_CREATE_FAIL = 'JOBPART_CREATE_FAIL';

export const JOBPART_FETCH_REQUEST = 'JOBPART_FETCH_REQUEST';
export const JOBPART_FETCH_SUCCESS = 'JOBPART_FETCH_SUCCESS';
export const JOBPART_FETCH_FAIL = 'JOBPART_FETCH_FAIL';

export const JOBPART_UPDATE_REQUEST = 'JOBPART_UPDATE_REQUEST';
export const JOBPART_UPDATE_SUCCESS = 'JOBPART_UPDATE_SUCCESS';
export const JOBPART_UPDATE_FAIL = 'JOBPART_UPDATE_FAIL';

export const JOBPART_DELETE_REQUEST = 'JOBPART_DELETE_REQUEST';
export const JOBPART_DELETE_SUCCESS = 'JOBPART_DELETE_SUCCESS';
export const JOBPART_DELETE_FAIL = 'JOBPART_DELETE_FAIL';

export const JOBPART_RESET_REDIRECT = 'JOB_RESET_REDIRECT';

export const JOBPART_DUEDATELIST_FETCH_REQUEST = 'JOBPART_DUEDATELIST_FETCH_REQUEST';
export const JOBPART_DUEDATELIST_FETCH_SUCCESS = 'JOBPART_DUEDATELIST_FETCH_SUCCESS';
export const JOBPART_DUEDATELIST_FETCH_FAIL = 'JOBPART_DUEDATELIST_FETCH_FAIL';

export const JOBPART_DUEDATE_FETCH_REQUEST = 'JOBPART_DUEDATE_FETCH_REQUEST';
export const JOBPART_DUEDATE_FETCH_SUCCESS = 'JOBPART_DUEDATE_FETCH_SUCCESS';
export const JOBPART_DUEDATE_FETCH_FAIL = 'JOBPART_DUEDATE_FETCH_FAIL';

export const JOBPART_DUEDATE_CREATE_REQUEST = 'JOBPART_DUEDATE_CREATE_REQUEST';
export const JOBPART_DUEDATE_CREATE_SUCCESS = 'JOBPART_DUEDATE_CREATE_SUCCESS';
export const JOBPART_DUEDATE_CREATE_FAIL = 'JOBPART_DUEDATE_CREATE_FAIL';

export const JOBPART_DUEDATE_UPDATE_REQUEST = 'JOBPART_DUEDATE_UPDATE_REQUEST';
export const JOBPART_DUEDATE_UPDATE_SUCCESS = 'JOBPART_DUEDATE_UPDATE_SUCCESS';
export const JOBPART_DUEDATE_UPDATE_FAIL = 'JOBPART_DUEDATE_UPDATE_FAIL';

export const JOBPART_DUEDATES_UPDATE_REQUEST = 'JOBPART_DUEDATES_UPDATE_REQUEST';
export const JOBPART_DUEDATES_UPDATE_SUCCESS = 'JOBPART_DUEDATES_UPDATE_SUCCESS';
export const JOBPART_DUEDATES_UPDATE_FAIL = 'JOBPART_DUEDATES_UPDATE_FAIL';

export const JOBPART_DUEDATE_DELETE_REQUEST = 'JOBPART_DUEDATE_DELETE_REQUEST';
export const JOBPART_DUEDATE_DELETE_SUCCESS = 'JOBPART_DUEDATE_DELETE_SUCCESS';
export const JOBPART_DUEDATE_DELETE_FAIL = 'JOBPART_DUEDATE_DELETE_FAIL';

export const DUEDATELIST_FETCH_REQUEST = 'DUEDATELIST_FETCH_REQUEST';
export const DUEDATELIST_FETCH_SUCCESS = 'DUEDATELIST_FETCH_SUCCESS';
export const DUEDATELIST_FETCH_FAIL = 'DUEDATELIST_FETCH_FAIL';

export const JOBPART_DUEDATE_RESET = 'JOBPART_DUEDATE_RESET';
