import { Container } from 'react-bootstrap';

// import styles from './homeScreen.module.css';

const HomeScreen = () => {
  return (
    <Container>
      {/* <section>Intro Video</section>
      <section>About the app + Login credentials</section>
      <section>
        <h2>Features</h2>
        <Carousel fade>
          <Carousel.Item>
            <img className="d-block w-100"  alt="First slide" />
            <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src="holder.js/800x400?text=Second slide&bg=282c34" alt="Second slide" />

            <Carousel.Caption>
              <h3>Second slide label</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src="holder.js/800x400?text=Third slide&bg=20232a" alt="Third slide" />

            <Carousel.Caption>
              <h3>Third slide label</h3>
              <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>
      <section>Contact</section> */}
    </Container>
  );
};

export default HomeScreen;
